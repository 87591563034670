import _taggedTemplateLiteral from "/builds/aoiChV12/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

// @ts-nocheck
import gql from 'graphql-tag';
export var AvailabilitiesFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment Availabilities on MaterialAvailability {\n  date\n  key\n  plantLoc\n  quantity\n  displayFromLink\n  displayInquireLink\n  messageType\n  contactInfo {\n    contactPhone\n    contactEmail\n  }\n  availabilityOverwriteMessage {\n    messageKey\n    messageValue\n    messageVariable1\n    messageVariable2\n    messageVariable3\n  }\n  supplementaryMessage {\n    messageKey\n    messageValue\n    messageVariable1\n    messageVariable2\n    messageVariable3\n  }\n}\n    "])));
export var AdditionalInfoFragmentDoc = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    fragment AdditionalInfo on CartAdditionalInfo {\n  carrierRestriction\n  unNumber\n  tariff\n  casNumber\n  jfcCode\n  pdcCode\n}\n    "])));
export var PromotionalMessageFragmentDoc = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    fragment PromotionalMessage on PromotionalMessage {\n  messageKey\n  messageValue\n  messageVariable1\n  messageVariable2\n  messageVariable3\n}\n    "])));
export var ValidMaterialPricingDetailFragmentDoc = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    fragment ValidMaterialPricingDetail on ValidMaterialPricing {\n  brand\n  type\n  currency\n  dealerId\n  listPriceCurrency\n  listPrice\n  shipsToday\n  freeFreight\n  catalogType\n  marketplaceOfferId\n  marketplaceSellerId\n  materialDescription\n  materialNumber\n  materialId\n  netPrice\n  packageSize\n  packageType\n  price\n  isBuyNow\n  product\n  productGroupSBU\n  productHierarchy\n  quantity\n  isPBAvailable\n  vendorSKU\n  isBlockedProduct\n  hidePriceMessageKey\n  expirationDate\n  availableQtyInStock\n  availabilities {\n    ...Availabilities\n  }\n  additionalInfo {\n    ...AdditionalInfo\n  }\n  promotionalMessage {\n    ...PromotionalMessage\n  }\n  ... @include(if: $displaySDS) {\n    sdsLanguages\n  }\n  minOrderQuantity\n}\n    ", "\n", "\n", ""])), AvailabilitiesFragmentDoc, AdditionalInfoFragmentDoc, PromotionalMessageFragmentDoc);
export var ReplacementProductDetailFragmentDoc = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    fragment ReplacementProductDetail on Product {\n  productNumber\n  name\n  description\n  sdsLanguages\n  images {\n    mediumUrl\n    altText\n  }\n  brand {\n    key\n    erpKey\n    name\n    logo {\n      smallUrl\n      altText\n    }\n  }\n}\n    "])));
export var AlternateMaterialDetailFragmentDoc = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    fragment AlternateMaterialDetail on Material {\n  number\n}\n    "])));
export var DiscontinuedMaterialPricingDetailFragmentDoc = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    fragment DiscontinuedMaterialPricingDetail on DiscontinuedMaterialPricing {\n  errorMsg\n  paramList\n  hideReplacementProductLink\n  displaySimilarProductLabel\n  hideTechnicalServiceLink\n  replacementProducts {\n    ...ReplacementProductDetail\n  }\n  alternateMaterials {\n    ...AlternateMaterialDetail\n  }\n}\n    ", "\n", ""])), ReplacementProductDetailFragmentDoc, AlternateMaterialDetailFragmentDoc);
export var ProductInfoMessageDetailFragmentDoc = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    fragment ProductInfoMessageDetail on ProductInfoMessage {\n  productNumber\n  messageType\n  message\n}\n    "])));
export var ProductPricingDetailFragmentDoc = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    fragment ProductPricingDetail on ProductPricing {\n  dealerId\n  productNumber\n  country\n  materialPricing {\n    ...ValidMaterialPricingDetail\n  }\n  discontinuedPricingInfo {\n    ...DiscontinuedMaterialPricingDetail\n  }\n  dchainMessage\n  productInfo {\n    ...ProductInfoMessageDetail\n  }\n}\n    ", "\n", "\n", ""])), ValidMaterialPricingDetailFragmentDoc, DiscontinuedMaterialPricingDetailFragmentDoc, ProductInfoMessageDetailFragmentDoc);
export var MaterialPricingDetailFragmentDoc = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    fragment MaterialPricingDetail on MaterialPricing {\n  materialPricing {\n    ...ValidMaterialPricingDetail\n  }\n  discontinuedPricingInfo {\n    ...DiscontinuedMaterialPricingDetail\n  }\n}\n    ", "\n", ""])), ValidMaterialPricingDetailFragmentDoc, DiscontinuedMaterialPricingDetailFragmentDoc);
export var ValidMaterialAvailabilityDetailFragmentDoc = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    fragment ValidMaterialAvailabilityDetail on ValidMaterialAvailability {\n  availabilities {\n    ...Availabilities\n  }\n}\n    ", ""])), AvailabilitiesFragmentDoc);
export var MaterialAvailabilitiesDetailFragmentDoc = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    fragment MaterialAvailabilitiesDetail on MaterialAvailabilities {\n  materialAvailabilities {\n    ...ValidMaterialAvailabilityDetail\n  }\n}\n    ", ""])), ValidMaterialAvailabilityDetailFragmentDoc);