import _toConsumableArray from "/builds/aoiChV12/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/builds/aoiChV12/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _forEach from "lodash/forEach";
import getConfig from 'next/config';
import { Analytics, GTMEventTypes, GTMEventCategory, EventValues } from '@sial/common-utils';
import groupBy from 'lodash/groupBy';
import { uTagLinkAdapter } from '@utils/tealiumAnalytics';
import { ConfiguratorProductType } from '@utils/analytics/configuratorAnalytics';
import { AddToCartPagesEnum, PaymentMethodValues, Dimension91 } from '@utils/analytics/enums';

var _getConfig = getConfig(),
    _getConfig$publicRunt = _getConfig.publicRuntimeConfig,
    analyticsAreEnabled = _getConfig$publicRunt.isAnalyticsEnabled,
    enableTealium = _getConfig$publicRunt.enableTealium,
    enableGoogleTagManager = _getConfig$publicRunt.enableGoogleTagManager,
    adobeLaunchId = _getConfig$publicRunt.adobeLaunchId,
    stratosMultiRegion = _getConfig$publicRunt.featureFlags.stratosMultiRegion; // Globally defines is ANY analytics platform should be used on the site.


export var isAnalyticsEnabled = analyticsAreEnabled; // If the flag to force enable GTM (enableGoogleTagManager) is set triggers the app to use the GTM tag.
// If not uses the stratosMultiRegion variable to determine this. stratosMultiRegion is all non-China sites.

export var showGTMTag = enableGoogleTagManager !== undefined ? enableGoogleTagManager : stratosMultiRegion; // If the flag is set to force enable Tealium (enableTealium) then Tealium events will be loaded and fired.
// If not use a NOT stratos multi region flag (China site) to set the Tealium usage flag to fire events or not.

export var isTealium = enableTealium !== undefined ? enableTealium : !showGTMTag; // Adobe Target Launch Event.

export var sendLaunchEvent = function sendLaunchEvent(props) {
  var _window, _window$adobeDataLaye;

  if (!adobeLaunchId || false) return;
  (_window = window) === null || _window === void 0 ? void 0 : (_window$adobeDataLaye = _window.adobeDataLayer) === null || _window$adobeDataLaye === void 0 ? void 0 : _window$adobeDataLaye.push(props);
};
var ga3DataModel = {
  eventCategory: 'Category name',
  eventAction: 'Category Action - Click, Hover, Whatever',
  eventLabel: 'Event Label - Text Of Link, etc',
  eventInteractionType: 1,
  purchaseSoldToNumber: 'Sold To Number',
  purchaseBillToNumber: 'Bill To Number',
  purchaseShipToNumber: 'Purchase Ship To',
  purchaseParticipantId: 'Participant ID?',
  // ecommerce: {} - Is ignored because it is radically different between UA and GA4. Consider using legacySendEvent() for payloads with eCommerce data.
  // One off custom dimension properties semingly used by UA.
  memberId: 'Member ID on me',
  newsLetterSignup: 'Yes/No'
};

var conformToPayload = function conformToPayload(obj, interfaceDef) {
  var conformedPayload = {};

  for (var key in interfaceDef) {
    if (typeof obj[key] !== 'undefined') {
      if (typeof interfaceDef[key] === 'object' && !Array.isArray(interfaceDef[key])) {
        //When a subkey that is an object is found, find the subkey in the modeled data structure and then recursively compare the two.
        conformedPayload[key] = conformToPayload(obj[key], interfaceDef[key]);
      } else {
        conformedPayload[key] = obj[key];
      }
    }
  }

  return conformedPayload;
};
/*
  Legacy Send Event Usage:

  There are times where the sendEvent() function for delivering UA and GA4 events at the same time is insufficient or detremental.
  This is most commonly found when the payload requires the ecommerce: property.

  The ecommerce data structure varies fairly significantly between the two platforms and as such they need to be sent in legacy mode with one sendEvent() for UA and one for GA4.
*/


export var legacySendEvent = function legacySendEvent(props) {
  Analytics.sendEvent(props, isAnalyticsEnabled, showGTMTag);
  sendLaunchEvent(props);
};
export var sendEvent = function sendEvent(props) {
  var payload = props.payload,
      eventType = props.eventType; // Legacy UA events still need submited to the datalayer for other third-party systems that have not adapted to new GA4 data structures.

  if (eventType) {
    var conformGa3Payload = conformToPayload(payload, ga3DataModel); //Make a new GA3 event object with the existing event type, and new validated/conformed payload.

    var ga3OnlyEvent = {
      eventType: eventType,
      payload: conformGa3Payload
    };
    showGTMTag && Analytics.sendEvent(ga3OnlyEvent, isAnalyticsEnabled, showGTMTag);
  } else {
    console.warn('Analytics: No eventType set. Legacy UA event not send to dataLayer.');
  }

  if ((payload === null || payload === void 0 ? void 0 : payload.event) === GTMEventTypes.AnalyticsEvent || (payload === null || payload === void 0 ? void 0 : payload.event) === eventType) {
    console.error("Analytics: GA4 'event' should not be the same as, or using a UA 'eventType' parameter.");
  }
  /*
    LOGIC:
    We read 'ga4Event' as the generic event type in GTM and our @sial/common-utils package.
    As such if a legacy UA event not yet refactored for GA4 is provided to this function we are going to assume it was a generic legacy event, and cast it as a generic event in GA4.
    If a specific event parameter is supplied we'll just assume it is correct and keep it as such.
     WHY:
    When the legacy Universal Analytics (UA) system was still active before July 1st 2024 the 'eventType' field was used to signal to the platform what type of event was being sent.
    Since Google Analytics has always had a variety of different event types that could be funneled into different parts of the platform, it was expected you declare the type of event passed along.
     GA4 which came fully online after the closure of has a similar system where there are generic events, as well as a host of recommended and 'enhanced' events used for things like ecommerce funnel tacking.
    Google provides a list of recommended trakcings here: https://support.google.com/analytics/answer/9267735?sjid=9296292270182979919-NA
     Additionally our Analytics implementation is loaded via @sial/common-utils and has a Google Tag Manager (GTM) script that interacts with our dataLayer.
    Meaning it also reads and manipulates data the Nextjs app is submitting to make sure it goes to the right place in our analytics infrastructure.
  */


  var ga4Payload = payload;
  ga4Payload.event = payload.event || 'ga4Event';
  /*
    In cases where a seemingly legacy UA event passes or is cast to a generic GA4 event,
    but there is actually a GA4 'event_type' present we want to instead set the 'event' as the 'event_name' and then drop the 'event_name' from the object.
    
    See GA4-332 for more details.
  */

  if (ga4Payload.event === 'ga4Event' && ga4Payload !== null && ga4Payload !== void 0 && ga4Payload.event_name) {
    ga4Payload.event = ga4Payload.event_name;
    payload.event_name = undefined;
  } // Convert the legacy eventWhatever payload parameters to new snake_case formatted GA4 and the Analytics team internally prefer.
  // If eventWhatever doesn't exist on any given payload it will explicitly set 'undefined'


  ga4Payload.event_category = ga4Payload === null || ga4Payload === void 0 ? void 0 : ga4Payload.eventCategory;
  ga4Payload.event_action = ga4Payload === null || ga4Payload === void 0 ? void 0 : ga4Payload.eventAction;
  ga4Payload.event_label = ga4Payload === null || ga4Payload === void 0 ? void 0 : ga4Payload.eventLabel; // The GA team asked that the legacy params be cleaned from GA4 payloads. This function quickly removes all matching keys

  var cleanedGa4Payload = {};
  var legacyParams = ['eventInteractionType', 'eventCategory', 'eventAction', 'eventLabel'];

  for (var _i = 0, _Object$entries = Object.entries(ga4Payload); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
        key = _Object$entries$_i[0],
        value = _Object$entries$_i[1];

    if (!legacyParams.includes(key)) {
      cleanedGa4Payload[key] = value;
    }
  } // A bit wonky since GA4 wants an object with only a single property of 'payload' and nothing else. This also makes eventType which triggers GA3 events omitted.


  showGTMTag && Analytics.sendEvent({
    payload: cleanedGa4Payload
  }, isAnalyticsEnabled, showGTMTag); // Push event data into the Adobe analytics layer. No changes here interface wise between GA4 and Adobe Analytics at this time.
  // Adobe Analytics should only be loaded and fire events when Analytics are enabled globally and GA4 is loaded.

  isAnalyticsEnabled && showGTMTag && sendLaunchEvent(props); // When Tealium is enabled we want to submit the uTagLink event with some Tealium specific payload modifications.

  isTealium && uTagLinkAdapter(cleanedGa4Payload);
}; // There is this legacy cart related event only used by configurators and the MTO team.
// It should eventually be refactored or removed but to do a hotfix for GA4-195

export var uaConfiguratorAddToCart = function uaConfiguratorAddToCart(_ref) {
  var productIds = _ref.productIds,
      actionTitle = _ref.actionTitle,
      ecommerce = _ref.ecommerce;
  legacySendEvent({
    eventType: GTMEventTypes.AddToCart,
    payload: {
      eventCategory: GTMEventCategory.AddToCart,
      eventAction: actionTitle,
      eventLabel: productIds,
      eventInteractionType: 0,
      ecommerce: ecommerce
    }
  });
};
/***
 * HELPER UTILITY FUNCTIONS AND CONSTANTS
 *
 * There are a number of functions in the analytics files made for simply extracting things like
 * price and currency codes from a line item.
 *
 * As well as a number of regex cleanups for item structure remappings.
 *
 * They'll all be houses below here and are imported as needed throughout other analytics files and the application.
 */
// regex that for stripping encoded unicode code string from product name

export var regexForStripEncodingUnicode = /([&][#](\d+)[;])/g; // regex for stripping html tab e.g <> from product name

export var regexForStripHTML = /(<([^>]+)>)/gi; // regex for stripping special characters that other than letter/space/number from product name

export var regexForStripSpecialChars = /[^\w\s]/gi; // regex for stripping trade mark, copy right symbol etc,
// some case we  have getting symbols instead of encoded unicode in product name

export var regexForStripSymbols = /[™®©]/g;
export var regexForStripBrackets = / *\{[^}]*\} */g;
export var regexForStripHTMLWithContent = /<.*>.*?/gi; // Tealium uses CNY as default currency, GA uses USD

export var DEFAULT_CURRENCY_CODE = showGTMTag ? 'USD' : 'CNY';
export var CART_DATA_STORAGE_KEY = 'CART_DATA_STORAGE_KEY';
export var ADD_TO_CART_GA_DATA_STORAGE_KEY = 'ADD_TO_CART_GA_DATA_STORAGE_KEY'; // Google Analytics accepts only "MXN" unit for Mexico, So changing mxp to mxn unit.

export var getCurrencyCode = function getCurrencyCode(currencyCode) {
  return (currencyCode === null || currencyCode === void 0 ? void 0 : currencyCode.toLowerCase()) === 'mxp' ? 'mxn' : currencyCode === null || currencyCode === void 0 ? void 0 : currencyCode.toLowerCase();
};
export var getCurrencyCodeFromProduct = function getCurrencyCodeFromProduct(materialPricing) {
  var _materialPricing$, _materialPricing$2;

  return (materialPricing !== null && materialPricing !== void 0 && materialPricing.length && (_materialPricing$ = materialPricing[0]) !== null && _materialPricing$ !== void 0 && _materialPricing$.currency ? (_materialPricing$2 = materialPricing[0]) === null || _materialPricing$2 === void 0 ? void 0 : _materialPricing$2.currency : DEFAULT_CURRENCY_CODE).toLowerCase();
};
export var mapPaymentMethod = function mapPaymentMethod() {
  var paymentMethod = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var isChargeback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var contractNumber = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var isB2BUser = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var paymentMethodString = '';

  switch (paymentMethod.toLowerCase()) {
    case 'cc':
      paymentMethodString = !!contractNumber ? PaymentMethodValues.cn : PaymentMethodValues.cc;
      break;

    case 'std':
      paymentMethodString = isChargeback ? PaymentMethodValues.chargeback : !!contractNumber ? PaymentMethodValues.cn : PaymentMethodValues.po;
      break;

    case 'mpower':
      paymentMethodString = PaymentMethodValues.mpower;
      break;

    case 'rapidred':
      paymentMethodString = PaymentMethodValues.rapidred;
      break;

    case 'cn':
      paymentMethodString = PaymentMethodValues.cn;
      break;

    case 'trevipay':
      paymentMethodString = PaymentMethodValues.po;
      break;

    default:
      paymentMethodString = isB2BUser ? PaymentMethodValues.b2b : PaymentMethodValues.others;
      break;
  }

  return paymentMethodString;
};
/*
  Price formatter function for analytics where if the value isn't a valid number we set it to null, and all valid numbers are parsed to be a type of a float with two digits of percision.

  This is to fix some inconsistencies we had when setting fallback prices in the event a price was undefined.
  For undefined prices we always want null.

  And all other prices should get two digits of percision and be a float. Not a number as a string.
*/

export var priceFormatter = function priceFormatter(priceValue) {
  // null, undefined, and '' (empty string) are all falsey in javascript.
  // If priceValue is ever one of these we simply should immediately give back the null undefined value requested by the analytics team.
  if (!priceValue) {
    return null;
  } // Try to parse input price Value to a number if it is defined.
  // Number gives back a NaN (falsey) value if it gets a string with things other than numeric characters and decimals. Which should return null for interface support with Google Analytics.


  var parsePrice = Number(priceValue);

  if (!parsePrice) {
    return null;
  } // Try to parse the number to a float with two digits. May not need a try/catch but number parsing in javascript can behave oddly sometimes so better safe than sorry.


  try {
    return parseFloat(parsePrice.toFixed(2));
  } catch (err) {
    console.error("[Analytics] Error Formatting Price:", err);
    return null;
  }
};
export var setPaClipData = function setPaClipData(values, materialPricing) {
  try {
    if (!values.length || !materialPricing.length) return;
    var products = values === null || values === void 0 ? void 0 : values.map(function (product) {
      var _toLowerCase, _toLowerCase$replace;

      var material = materialPricing === null || materialPricing === void 0 ? void 0 : materialPricing.find(function (item) {
        return item.materialNumber === product.materialNumber;
      });
      return {
        id: ((material === null || material === void 0 ? void 0 : material.product) || EventValues.Empty).toLowerCase(),
        name: (_toLowerCase = ((material === null || material === void 0 ? void 0 : material.materialDescription) || EventValues.Empty).toLowerCase()) === null || _toLowerCase === void 0 ? void 0 : (_toLowerCase$replace = _toLowerCase.replace(regexForStripHTML, '')) === null || _toLowerCase$replace === void 0 ? void 0 : _toLowerCase$replace.replace(regexForStripSpecialChars, ''),
        variant: ((material === null || material === void 0 ? void 0 : material.materialNumber) || EventValues.Empty).toLowerCase(),
        brand: ((material === null || material === void 0 ? void 0 : material.brand) || EventValues.Empty).toLowerCase(),
        quantity: product === null || product === void 0 ? void 0 : product.quantity,
        price: priceFormatter(material === null || material === void 0 ? void 0 : material.price)
      };
    });
    return products.length ? products : [];
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error);
    return [];
  }
};
export var setMaterialData = function setMaterialData(values, favorite, pageName, bundle) {
  try {
    var itemGroups = groupBy(values || [], function (item) {
      return item.materialNumber;
    });
    var priceGroups = groupBy(favorite || [], function (item) {
      var _item$material;

      return item.materialNumber || (item === null || item === void 0 ? void 0 : (_item$material = item.material) === null || _item$material === void 0 ? void 0 : _item$material.number);
    });
    var products = Object.values(itemGroups).map(function (group, index) {
      var _ref2, _Object$values$index, _ref3, _materialPricing$mate, _materialPricing$mate2, _ref4, _ref4$replace, _ref4$replace$replace, _ref4$replace$replace2, _materialPricing$mate3, _materialPricing$mate4, _materialPricing$mate5, _materialPricing$mate6, _ref5, _materialPricing$mate7, _ref6, _materialPricing$mate8, _materialPricing$mate9, _materialPricing$mate10, _materialPricing$mate11;

      var item = group[0];
      var materialPricing = favorite === null || favorite === void 0 ? void 0 : favorite.find(function (elem) {
        var _elem$material;

        if (pageName === AddToCartPagesEnum.QuoteDetails || pageName === AddToCartPagesEnum.QuickOrderQuotesModal || pageName === AddToCartPagesEnum.AccountDashboardQuote) {
          var _elem$materialInfo;

          return (elem === null || elem === void 0 ? void 0 : (_elem$materialInfo = elem.materialInfo) === null || _elem$materialInfo === void 0 ? void 0 : _elem$materialInfo.number) === (item === null || item === void 0 ? void 0 : item.materialNumber);
        }

        if (pageName === AddToCartPagesEnum.PromoBundle) {
          return (elem === null || elem === void 0 ? void 0 : elem.materialNumber) === (item === null || item === void 0 ? void 0 : item.materialNumber);
        }

        return (elem === null || elem === void 0 ? void 0 : (_elem$material = elem.material) === null || _elem$material === void 0 ? void 0 : _elem$material.number) === (item === null || item === void 0 ? void 0 : item.materialNumber);
      });
      var avgGroupPrice = (_ref2 = ((_Object$values$index = Object.values(priceGroups)[index]) === null || _Object$values$index === void 0 ? void 0 : _Object$values$index.reduce(function (acc, item) {
        var _item$material2;

        return ((item === null || item === void 0 ? void 0 : (_item$material2 = item.material) === null || _item$material2 === void 0 ? void 0 : _item$material2.price) || (item === null || item === void 0 ? void 0 : item.pricePerUnit) || (item === null || item === void 0 ? void 0 : item.listPrice) || 0) + acc;
      }, 0)) / group.length) === null || _ref2 === void 0 ? void 0 : _ref2.toFixed(2);

      if (pageName === AddToCartPagesEnum.QuoteDetails || pageName === AddToCartPagesEnum.QuickOrderQuotesModal || pageName === AddToCartPagesEnum.AccountDashboardQuote) {
        materialPricing.material = materialPricing.materialInfo;
      }

      return {
        id: (_ref3 = (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate = materialPricing.material) === null || _materialPricing$mate === void 0 ? void 0 : _materialPricing$mate.product) || (materialPricing === null || materialPricing === void 0 ? void 0 : materialPricing.product) || (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate2 = materialPricing.material) === null || _materialPricing$mate2 === void 0 ? void 0 : _materialPricing$mate2.number) || (materialPricing === null || materialPricing === void 0 ? void 0 : materialPricing.materialNumber) || EventValues.Empty) === null || _ref3 === void 0 ? void 0 : _ref3.toLowerCase(),
        name: ((_ref4 = (materialPricing === null || materialPricing === void 0 ? void 0 : materialPricing.type) === ConfiguratorProductType.Configurator ? (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate3 = materialPricing.material) === null || _materialPricing$mate3 === void 0 ? void 0 : _materialPricing$mate3.description) || (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate4 = materialPricing.material) === null || _materialPricing$mate4 === void 0 ? void 0 : _materialPricing$mate4.name) : (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate5 = materialPricing.material) === null || _materialPricing$mate5 === void 0 ? void 0 : _materialPricing$mate5.name) || (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate6 = materialPricing.material) === null || _materialPricing$mate6 === void 0 ? void 0 : _materialPricing$mate6.description) || (materialPricing === null || materialPricing === void 0 ? void 0 : materialPricing.materialName)) === null || _ref4 === void 0 ? void 0 : (_ref4$replace = _ref4.replace(regexForStripEncodingUnicode, '')) === null || _ref4$replace === void 0 ? void 0 : (_ref4$replace$replace = _ref4$replace.replace(regexForStripHTML, '')) === null || _ref4$replace$replace === void 0 ? void 0 : (_ref4$replace$replace2 = _ref4$replace$replace.replace(regexForStripSymbols, '')) === null || _ref4$replace$replace2 === void 0 ? void 0 : _ref4$replace$replace2.toLowerCase()) || EventValues.Empty,
        variant: (_ref5 = (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate7 = materialPricing.material) === null || _materialPricing$mate7 === void 0 ? void 0 : _materialPricing$mate7.number) || (materialPricing === null || materialPricing === void 0 ? void 0 : materialPricing.materialNumber) || EventValues.Empty) === null || _ref5 === void 0 ? void 0 : _ref5.toLowerCase(),
        brand: (_ref6 = (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate8 = materialPricing.material) === null || _materialPricing$mate8 === void 0 ? void 0 : (_materialPricing$mate9 = _materialPricing$mate8.brand) === null || _materialPricing$mate9 === void 0 ? void 0 : _materialPricing$mate9.key) || (materialPricing === null || materialPricing === void 0 ? void 0 : (_materialPricing$mate10 = materialPricing.material) === null || _materialPricing$mate10 === void 0 ? void 0 : (_materialPricing$mate11 = _materialPricing$mate10.brand) === null || _materialPricing$mate11 === void 0 ? void 0 : _materialPricing$mate11.name) || (materialPricing === null || materialPricing === void 0 ? void 0 : materialPricing.brand) || EventValues.Empty) === null || _ref6 === void 0 ? void 0 : _ref6.toLowerCase(),
        coupon: (bundle === null || bundle === void 0 ? void 0 : bundle.bundleId) || EventValues.Empty,
        quantity: bundle ? (bundle === null || bundle === void 0 ? void 0 : bundle.quantity) * (item === null || item === void 0 ? void 0 : item.quantity) : group.reduce(function (acc, item) {
          return acc + (item === null || item === void 0 ? void 0 : item.quantity);
        }, 0) || 1,
        price: priceFormatter(avgGroupPrice),
        dimension91: 'standard'
      };
    });
    return products ? products : [];
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error);
    return [];
  }
};
export var clubbingUpProducts = function clubbingUpProducts(products, page, dimension91) {
  var flattenedProducts = [];

  if (page === 'order_acknowledgement') {
    flattenedProducts = products.filter(function (item) {
      return (item === null || item === void 0 ? void 0 : item.type) === 'PromotionalBundle';
    });
  } else {
    flattenedProducts = products.flatMap(function (item) {
      return item === null || item === void 0 ? void 0 : item.bundleItems;
    });
  } // flattenedProducts =
  //   flattenedProducts.length &&
  //   flattenedProducts.map((item, index) => {
  //     item.bundleItem = index
  //     return item
  //   })


  var promoItems = groupBy(flattenedProducts || [], function (item) {
    var _item$material3;

    return item === null || item === void 0 ? void 0 : (_item$material3 = item.material) === null || _item$material3 === void 0 ? void 0 : _item$material3.id;
  });

  _forEach(promoItems, function (_value, key) {
    promoItems[key] = groupBy(promoItems[key] || [], function (item) {
      return item === null || item === void 0 ? void 0 : item.promoCode;
    });
  });

  var promoProducts = Object.values(promoItems).map(function (group) {
    return Object.values(group).map(function (group1) {
      var _ref7, _item$material4, _ref8, _ref8$replace, _ref8$replace$replace, _item$material5, _item$material6, _item$material7, _item$material7$numbe, _ref9, _item$material8, _item$material8$brand;

      var item = group1[0];
      var avgGroupPrice = group1.reduce(function (acc, item) {
        var _item$pricing;

        return (((_item$pricing = item.pricing) === null || _item$pricing === void 0 ? void 0 : _item$pricing.price) || (item === null || item === void 0 ? void 0 : item.pricePerUnit) || (item === null || item === void 0 ? void 0 : item.listPrice) || 0) + acc;
      }, 0) / group1.length;
      return {
        id: (_ref7 = (item === null || item === void 0 ? void 0 : (_item$material4 = item.material) === null || _item$material4 === void 0 ? void 0 : _item$material4.product) || EventValues.Empty) === null || _ref7 === void 0 ? void 0 : _ref7.toLowerCase(),
        name: ((_ref8 = ((_item$material5 = item.material) === null || _item$material5 === void 0 ? void 0 : _item$material5.name) || ((_item$material6 = item.material) === null || _item$material6 === void 0 ? void 0 : _item$material6.description)) === null || _ref8 === void 0 ? void 0 : (_ref8$replace = _ref8.replace(regexForStripHTML, '')) === null || _ref8$replace === void 0 ? void 0 : (_ref8$replace$replace = _ref8$replace.replace(regexForStripEncodingUnicode, '')) === null || _ref8$replace$replace === void 0 ? void 0 : _ref8$replace$replace.toLowerCase()) || EventValues.Empty,
        variant: (item === null || item === void 0 ? void 0 : (_item$material7 = item.material) === null || _item$material7 === void 0 ? void 0 : (_item$material7$numbe = _item$material7.number) === null || _item$material7$numbe === void 0 ? void 0 : _item$material7$numbe.toLowerCase()) || EventValues.Empty,
        brand: (_ref9 = (item === null || item === void 0 ? void 0 : (_item$material8 = item.material) === null || _item$material8 === void 0 ? void 0 : (_item$material8$brand = _item$material8.brand) === null || _item$material8$brand === void 0 ? void 0 : _item$material8$brand.key) || EventValues.Empty) === null || _ref9 === void 0 ? void 0 : _ref9.toLowerCase(),
        quantity: group1.reduce(function (acc, item) {
          return acc + (item === null || item === void 0 ? void 0 : item.quantity);
        }, 0) || 1,
        price: (avgGroupPrice === null || avgGroupPrice === void 0 ? void 0 : avgGroupPrice.toString()) || '0.00',
        coupon: (item === null || item === void 0 ? void 0 : item.promoCode) || EventValues.Empty,
        discount: item === null || item === void 0 ? void 0 : item.discount
      };
    });
  });
  var flattenedProd = promoProducts.length && (promoProducts === null || promoProducts === void 0 ? void 0 : promoProducts.flat(1)) || [];
  var nonBundleItems = products.filter(function (item) {
    return (item === null || item === void 0 ? void 0 : item.type) !== 'PromotionalBundle';
  });
  var itemGroups = groupBy(nonBundleItems || [], function (item) {
    var _item$emproveDossierI, _item$material9;

    return (item === null || item === void 0 ? void 0 : item.type) === EventValues.Emprove ? item === null || item === void 0 ? void 0 : (_item$emproveDossierI = item.emproveDossierInfo) === null || _item$emproveDossierI === void 0 ? void 0 : _item$emproveDossierI.dossierMaterialNumber : item === null || item === void 0 ? void 0 : (_item$material9 = item.material) === null || _item$material9 === void 0 ? void 0 : _item$material9.id;
  });
  var productsArray = Object.values(itemGroups).map(function (group) {
    var item = group[0];
    var avgGroupPrice = group.reduce(function (acc, item) {
      var _item$pricing2;

      return (((_item$pricing2 = item.pricing) === null || _item$pricing2 === void 0 ? void 0 : _item$pricing2.price) || (item === null || item === void 0 ? void 0 : item.pricePerUnit) || (item === null || item === void 0 ? void 0 : item.listPrice) || 0) + acc;
    }, 0) / group.length;

    if (item.type === EventValues.Emprove) {
      var _item$material10, _item$emproveDossierI2, _item$material11, _item$material11$bran;

      return {
        id: ((item === null || item === void 0 ? void 0 : (_item$material10 = item.material) === null || _item$material10 === void 0 ? void 0 : _item$material10.id) || EventValues.Empty).toLowerCase(),
        name: ((item.material.description ? item.material.description : item.material.name) || EventValues.Empty).toLowerCase().replace(regexForStripHTML, '').replace(regexForStripEncodingUnicode, ''),
        variant: ((item === null || item === void 0 ? void 0 : (_item$emproveDossierI2 = item.emproveDossierInfo) === null || _item$emproveDossierI2 === void 0 ? void 0 : _item$emproveDossierI2.dossierMaterialNumber) || EventValues.Empty).toLowerCase(),
        brand: ((item === null || item === void 0 ? void 0 : (_item$material11 = item.material) === null || _item$material11 === void 0 ? void 0 : (_item$material11$bran = _item$material11.brand) === null || _item$material11$bran === void 0 ? void 0 : _item$material11$bran.name) || EventValues.SialBrand).toLowerCase(),
        quantity: 1,
        price: priceFormatter(avgGroupPrice),
        coupon: item.promoCode || EventValues.Empty,
        dimension91: dimension91 !== null && dimension91 !== void 0 ? dimension91 : Dimension91.Standard,
        discount: item === null || item === void 0 ? void 0 : item.discount
      };
    } else {
      var _item$material12, _item$material13, _item$material14, _item$material15, _item$material16, _item$material17, _item$material18, _item$material19, _item$material19$bran, _item$material20, _item$material20$bran;

      return {
        id: ((item === null || item === void 0 ? void 0 : item.type) === ConfiguratorProductType.Configurator ? (item === null || item === void 0 ? void 0 : (_item$material12 = item.material) === null || _item$material12 === void 0 ? void 0 : _item$material12.number) || EventValues.Empty : (item === null || item === void 0 ? void 0 : (_item$material13 = item.material) === null || _item$material13 === void 0 ? void 0 : _item$material13.product) || EventValues.Empty).toLowerCase(),
        name: (((item === null || item === void 0 ? void 0 : item.type) === ConfiguratorProductType.Configurator ? ((_item$material14 = item.material) === null || _item$material14 === void 0 ? void 0 : _item$material14.description) || ((_item$material15 = item.material) === null || _item$material15 === void 0 ? void 0 : _item$material15.name) : ((_item$material16 = item.material) === null || _item$material16 === void 0 ? void 0 : _item$material16.name) || ((_item$material17 = item.material) === null || _item$material17 === void 0 ? void 0 : _item$material17.description)) || EventValues.Empty).toLowerCase().replace(regexForStripHTML, '').replace(regexForStripEncodingUnicode, ''),
        variant: ((item === null || item === void 0 ? void 0 : (_item$material18 = item.material) === null || _item$material18 === void 0 ? void 0 : _item$material18.number) || EventValues.Empty).toLowerCase(),
        brand: ((item === null || item === void 0 ? void 0 : item.type) === ConfiguratorProductType.Configurator ? (item === null || item === void 0 ? void 0 : (_item$material19 = item.material) === null || _item$material19 === void 0 ? void 0 : (_item$material19$bran = _item$material19.brand) === null || _item$material19$bran === void 0 ? void 0 : _item$material19$bran.name) || EventValues.Empty : (item === null || item === void 0 ? void 0 : (_item$material20 = item.material) === null || _item$material20 === void 0 ? void 0 : (_item$material20$bran = _item$material20.brand) === null || _item$material20$bran === void 0 ? void 0 : _item$material20$bran.key) || EventValues.Empty).toLowerCase(),
        quantity: group.reduce(function (acc, item) {
          return acc + item.quantity;
        }, 0) || 1,
        price: priceFormatter(avgGroupPrice),
        coupon: item.promoCode || EventValues.Empty,
        dimension91: dimension91 !== null && dimension91 !== void 0 ? dimension91 : Dimension91.Standard,
        discount: item === null || item === void 0 ? void 0 : item.discount
      };
    }
  });
  return [].concat(_toConsumableArray(productsArray), _toConsumableArray(flattenedProd)) || [];
};