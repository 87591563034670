import getConfig from 'next/config';
import { useEffect } from 'react';
export function useOptimizely() {
  var _getConfig = getConfig(),
      optimizelyId = _getConfig.publicRuntimeConfig.optimizelyId;

  var activateOptlyPage = function activateOptlyPage(optlyPage) {
    window.optimizely.push({
      type: 'page',
      pageName: optlyPage.apiName,
      isActive: false
    });
    window.optimizely.push({
      type: 'page',
      pageName: optlyPage.apiName
    });
  };

  useEffect(function () {
    var _window, _window$optimizely;

    if (!optimizelyId) return;
    var optlyPages = ((_window = window) === null || _window === void 0 ? void 0 : (_window$optimizely = _window.optimizely) === null || _window$optimizely === void 0 ? void 0 : _window$optimizely.get('data').pages) || {};

    for (var page in optlyPages) {
      activateOptlyPage(optlyPages[page]);
    }
  });
}
export default useOptimizely;