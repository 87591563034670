import _slicedToArray from "/builds/aoiChV12/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { parseUrl } from '@utils/parseUrl';
import { useCurrentUser, SitePreference } from '@src/utils/useCurrentUser';
import { useCountriesData } from './useCountriesData';
export var useCountryName = function useCountryName(countryCode) {
  var _useCountriesData = useCountriesData(),
      getCountryProp = _useCountriesData.getCountryProp;

  if (!countryCode) {
    return null;
  }

  return getCountryProp(countryCode, 'displayName');
};
export var getLocalizedUrl = function getLocalizedUrl(userSession, url) {
  // TODO STRAT-13269 consolidate this with server/utils/https.ts#parsedUrlWithBasename
  if (!url) return {
    href: '',
    as: ''
  };
  var language = userSession.language.toLowerCase();
  var country = userSession.country.toUpperCase();

  var _parseUrl = parseUrl(url),
      _parseUrl$query = _parseUrl.query,
      query = _parseUrl$query === void 0 ? '' : _parseUrl$query,
      _parseUrl$path = _parseUrl.path,
      path = _parseUrl$path === void 0 ? '' : _parseUrl$path;

  var trimmedPath = (path !== null && path !== void 0 ? path : '').replace(/\/$/, '');
  return {
    href: "/[country]/[language]".concat(trimmedPath).concat(query),
    as: "/".concat(country, "/").concat(language).concat(trimmedPath).concat(query)
  };
};

/**
 * @function parseRegionalUrl
 *
 * This splits up a regional URL to give the country, language, & everything after it.
 *
 * e.g. http://www.sigmaaldrich.com/US/en/search/trizma?page=1
 *  will return:
 *    {
 *      basename: {
 *        country: 'US',
 *        language: 'en'
 *      },
 *      afterBasename: '/search/trizma?page=1',
 *      pathname: '/US/en/search/trizma?page=1'
 *      origin: 'http://www.sigmaaldrich.com',
 *    }
 *
 * @param url
 */
export var parseRegionalUrl = function parseRegionalUrl(url) {
  var parseableUrl = url || (true ? window.location.href : null);

  if (parseableUrl) {
    var _parseUrl2 = parseUrl(parseableUrl),
        protocol = _parseUrl2.protocol,
        host = _parseUrl2.host,
        country = _parseUrl2.country,
        language = _parseUrl2.language,
        _parseUrl2$path = _parseUrl2.path,
        path = _parseUrl2$path === void 0 ? '' : _parseUrl2$path,
        _parseUrl2$query = _parseUrl2.query,
        query = _parseUrl2$query === void 0 ? '' : _parseUrl2$query;

    var isRegional = country && language;
    if (!isRegional) return {
      basename: {}
    }; // TODO: remove this logic and refactor parseUrl to detect fragments.

    var _path$split = path.split('#'),
        _path$split2 = _slicedToArray(_path$split, 2),
        realPath = _path$split2[0],
        pathFragment = _path$split2[1];

    var _query$split = query.split('#'),
        _query$split2 = _slicedToArray(_query$split, 2),
        realQuery = _query$split2[0],
        queryFragment = _query$split2[1];

    return {
      basename: {
        country: country,
        language: language
      },
      fragment: pathFragment || queryFragment,
      pathname: "/".concat(country, "/").concat(language).concat(realPath),
      afterBasename: "".concat(realPath).concat(realQuery),
      pathAfterBasename: realPath,
      query: realQuery,
      origin: "".concat(protocol, "://").concat(host)
    };
  } else {
    return {
      basename: {}
    };
  }
}; // Note: If you are testing this functionality, you must either provide values
// at `__mocks_/next/config.js` or stub `next/config` in your test

export var useBuildingBlocksCountry = function useBuildingBlocksCountry() {
  var _currentUser$metadata;

  var _useCurrentUser = useCurrentUser(),
      currentUser = _useCurrentUser.currentUser,
      getSitePreference = _useCurrentUser.getSitePreference;

  var countryLevelFlagEnabled = !!(currentUser !== null && currentUser !== void 0 && (_currentUser$metadata = currentUser.metadata) !== null && _currentUser$metadata !== void 0 && _currentUser$metadata.isBuildingBlockEnabled);

  if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.__typename) === 'LoggedInUser') {
    var userLevelFlagEnabled = !getSitePreference(SitePreference.HideBuildingBlocks);
    return countryLevelFlagEnabled && userLevelFlagEnabled;
  }

  return countryLevelFlagEnabled;
};
export function removeCountryLanguage(url) {
  var _parseUrl3 = parseUrl(url),
      country = _parseUrl3.country,
      language = _parseUrl3.language;

  if (country && language) {
    url = url.replace("/".concat(country, "/").concat(language), '');
  }

  return url;
}
/**
 * This function returns true if no country/lang prefix is present in the url,
 *  or if cookie values don't match the prefix.
 * @param {UserSession} userSession contains users country and language specified in the cookies
 * @param {url} url to check if needs localizing
 * @returns boolean
 */

export function needsLocalizedUrl(userSession, url) {
  var _parseUrl4 = parseUrl(url),
      urlCountry = _parseUrl4.country,
      urlLanguage = _parseUrl4.language;

  var hasPrefix = !!urlCountry && !!urlLanguage;
  var cookiesDontMatchPrefix = (urlCountry === null || urlCountry === void 0 ? void 0 : urlCountry.toLowerCase()) !== userSession.country.toLowerCase() || (urlLanguage === null || urlLanguage === void 0 ? void 0 : urlLanguage.toLowerCase()) !== userSession.language.toLowerCase();
  return !hasPrefix || hasPrefix && cookiesDontMatchPrefix;
}